import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Selector from "../components/Selector";
import { Box, Divider, CircularProgress, IconButton } from "@mui/material";
import { PrimeReactProvider } from "primereact/api";
import "./Analytics.css";

import CustomBtn from "../components/CustomBtn";
import { convertDate } from "../helperFunc/convertDate";

import { Link, useLocation, useNavigate } from "react-router-dom";

import { ArrowBack } from "@mui/icons-material";

import { Calendar } from "primereact/calendar";
import createInstance from "../api";
import VoltageGraph from "../components/Graphs/VoltageGraph";
import EnergyGraph from "../components/Graphs/EnergyGraph";
import CurrentGraph from "../components/Graphs/CurrentGraph";
import LoadGraph from "../components/Graphs/LoadGraph";
import CarbonGraph from "../components/Graphs/CarbonEmission";
import PFgraph from "../components/Graphs/PFgraph";

import ProcessData from "../helperFunc/fillMinuteData";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import PresetsModal from "../components/PresetsModal";


import dayjs from "dayjs";
import {
  LocalizationProvider,
  // MobileTimePicker,
  TimePicker,
  renderTimeViewClock,
} from "@mui/x-date-pickers";
// import DataImage from "../components/Graphs/DataImage";
import HermonicsGraph from "../components/Graphs/VoltageHarmonicsGraph";
import ShiftWiseEnergyGraph from "../components/Graphs/ShiftwiseEnergyGraph";
import VoltageHermonicsGraph from "../components/Graphs/VoltageHarmonicsGraph";
import CurrentHermonicsGraph from "../components/Graphs/CurrentHarmonicsGraph";

const Analytics = () => {
  let api = createInstance();

  const location = useLocation();
  const data = location.state;

  let gateWayData = useSelector((state) => state.gateWayData);
  let clientData = useSelector((state) => state.clientData);
  let [graphData, setGraphData] = useState([]);
  let [filteredgraphData, setFilteredGraphData] = useState([]);
  let [shiftsGraphData, setShiftsGraphData] = useState([]);


  const [startDat, setStartDate] = useState(null);
  const [endDat, setEndDate] = useState(null);
  const [node, setNode] = useState(data === null ? "" : data.nodeId);
  const [gateway, setgateway] = useState(data === null ? "" : data.gateWayId);
  const [loading, setLoading] = useState(false);
  const [showGraph, setShowGraph] = useState(false);
  const [showTime, setShowTime] = useState(true);
  const [showMessage, setShowMessage] = useState(false);
  const [activePreset, setActivePreset] = useState(null);
  const [dataType, setDataType] = useState("Energy");
  const [openPreset, setOpenPreset] = useState(false);
  const [timeslug, setTimeslug] = useState(null)
  const [nodeConsumptions, setNodeConsumptions] = useState({});


  const handleOpenPreset = () => setOpenPreset(true);
  const handleClosePreset = () => setOpenPreset(false);




  const fromDefault = new Date();
  fromDefault.setHours(0, 0, 0, 0);

  const toDefault = new Date();

  const [fromTime, setFromTime] = useState(dayjs(fromDefault));

  const [toTime, setToTime] = useState(dayjs(toDefault));

  let nodeData = {};
  let gateWayIds = {};
  gateWayData.map((data) => {

    nodeData[data.gatewayID] = {};
    Object.keys(data).filter((device) => {
      if (
        device !== "noofnode" &&
        device.toLowerCase().includes("node") &&
        data[device].name &&
        data[device].mac !== "0:0:0:0:0:0"
      ) {
        return (nodeData[data.gatewayID] = {
          ...nodeData[data.gatewayID],
          [device]: data[device],
        });
      }
    });
    gateWayIds[data.gatewayID] = data;

  });

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const handleNode = (e) => {
    setNode(e.target.value);
  };
  const [nodes, setNodes] = useState(data === null ? [] : nodeData[gateway]);


  let gatewayName = Object.keys(gateWayIds).map((gate) => {
    return gateWayIds[gate].name;
  });

  let nodeNames = Object.keys(nodes).map((node) => {
    return nodes[node].name;
  });

  const handleGateWay = (e) => {
    setGraphData([]);
    setFilteredGraphData([]);
    setgateway(e.target.value);
    setNode("");
    setNodes(nodeData[e.target.value]);
    setNodeConsumptions({});
  };


  const handleDataType = (e) => {
    setDataType(e.target.value);
  };


  const submitHandler = async (e) => {
    e.preventDefault();
    let sd = startDat;
    sd.setHours(fromTime.hour(), fromTime.minute(), 0, 0);

    let ed = endDat;

    ed.setHours(toTime.hour(), toTime.minute(), 0, 0);

    if (sd > ed) {
      alert("start time cannot be greater than end time");
      return;
    }
    setGraphData([]);
    setFilteredGraphData([]);
    setShiftsGraphData([])
    setShowMessage(false);


    let slug = "";

    setShowGraph(false);
    setLoading(true);

    let startDate = convertDate(sd);
    let endDate = convertDate(ed);
    let res;
    let body;

    let diff = (ed - sd) / 3600000;
    if (diff <= 1) {
      // day wise data
      setShowTime(true);

      setTimeslug("hour")
      body = {
        starthour: startDate,
        endhour: endDate,
        slug: "hour",
      };
      slug = "hour";
    } else if (diff > 1 && diff <= 48) {
      setShowTime(true);
      setTimeslug("day");
      body = {
        starthour: startDate.substring(0, 10) + "00",
        endhour: endDate.substring(0, 10) + "00",
        slug: "day",

      };
      console.log(body);
    } else {
      setShowTime(false);
      setTimeslug("month");

      body = {
        starthour: startDate.substring(0, 8) + "0000",
        endhour: endDate.substring(0, 8) + "0000",
        slug: "month",
        shifts: dataType === "ShiftwiseEnergy" ? true : false,
      };
    }

    body = { ...body, GatewayID: gateway };
    res = await api.post("/rangedata", body);
    let data = null;

    data = [...res.data];
    if (slug === "hour") {
      data = ProcessData([...data], node, startDate, endDate);
    }
    data.reverse();
    if (dataType === "ShiftwiseEnergy") {
      setShiftsGraphData(data)
      setLoading(false);
      setShowGraph(true);
    }
    else {
      setGraphData(data);
      setFilteredGraphData(data)
      setLoading(false);
      setShowGraph(true);

    }



    if (slug === "hour") {
      const nodeTotals = {};
      Object.keys(data[0])
        .filter(key => key.startsWith("node"))
        .forEach(nodeKey => {
          const lastValue = data[0][nodeKey]?.Mean_Eact || 0;
          const firstValue = data[data.length - 1][nodeKey]?.Mean_Eact || 0;
          nodeTotals[nodeKey] = lastValue - firstValue;
        });
      setNodeConsumptions(nodeTotals)
    } else {
      const nodeTotals = {};
      data.forEach(record => {
        Object.keys(record).forEach(key => {
          if (key.startsWith('node') && record[key].Mean_Eact !== undefined) {
            if (!nodeTotals[key]) {
              nodeTotals[key] = 0;
            }
            nodeTotals[key] += record[key].Mean_Eact;
          }
        });
      });
      setNodeConsumptions(nodeTotals)
    }
    setLoading(false);
  };


  const handlePreset = (e) => {
    let id = e.target.id;
    setActivePreset(id);

    let end = new Date();
    let start;

    if (id === "1hr") {
      start = new Date(end.getTime() - 60 * 60 * 1000);
    } else if (id === "24hr") {
      start = new Date(end.getTime() - 24 * 60 * 60 * 1000);
      start.setMinutes(0, 0, 0);
      end.setMinutes(0, 0, 0)
    } else if (id === "1week") {
      start = new Date(end.getTime() - 7 * 24 * 60 * 60 * 1000);
      start.setHours(0, 0, 0, 0);
      end.setHours(0, 0, 0, 0);
    } else if (id === "1month") {
      start = new Date();
      start.setMonth(end.getMonth() - 1);
      start.setHours(0, 0, 0, 0);
      end.setHours(0, 0, 0, 0);
    }
    setStartDate(start);
    let startTime = new Date();
    startTime.setHours(start.getHours());
    startTime.setMinutes(start.getMinutes());
    setFromTime(dayjs(startTime));
    setEndDate(end);
    let endTime = new Date();
    endTime.setHours(end.getHours());
    endTime.setMinutes(end.getMinutes());
    setToTime(dayjs(endTime));
  };




  //voltage and current graph height 20px less because of custom legends
  const renderGraph = (graphType) => {
    switch (graphType) {
      case "Voltage":
        return (
          <VoltageGraph
            data={filteredgraphData}
            GatewayID={gateway}
            nodeSelect={node}
            showTime={showTime}
            graphHeight="380px"
          ></VoltageGraph>
        );
      case "Current":
        return (
          <CurrentGraph
            data={filteredgraphData}
            GatewayID={gateway}
            nodeSelect={node}
            showTime={showTime}
            graphHeight="380px"
          />
        );
      case "Energy":
        return (
          <>
            {
              Object.keys(nodeConsumptions).length !== 0 ?
                <h1 className="context"> &nbsp; Total consumption : {nodeConsumptions[node].toFixed(2) || 0} kWh </h1>
                : ""
            }
            <EnergyGraph
              data={filteredgraphData}
              GatewayID={gateway}
              nodeSelect={node}
              showTime={showTime}
              graphHeight="400px"
              slug={timeslug}
            ></EnergyGraph>


          </>
        );
      case "Power factor":
        return (
          <PFgraph
            data={filteredgraphData}
            GatewayID={gateway}
            nodeSelect={node}
            showTime={showTime}
            graphHeight="400px"
          ></PFgraph>
        );

      case "Carbon emissions":
        return (
          <CarbonGraph
            data={filteredgraphData}
            GatewayID={gateway}
            nodeSelect={node}
            showTime={showTime}
            graphHeight="400px"
          ></CarbonGraph>
        );
      case "Load":
        return (
          <LoadGraph
            data={filteredgraphData}
            GatewayID={gateway}
            nodeSelect={node}
            showTime={showTime}
            graphHeight="400px"
          ></LoadGraph>
        );

      case "VoltageHermonics":
        return (
          <VoltageHermonicsGraph
            data={filteredgraphData}
            GatewayID={gateway}
            nodeSelect={node}
            showTime={showTime}
            graphHeight="380px"
          ></VoltageHermonicsGraph>
        );

      case "CurrentHermonics":
        return (
          <CurrentHermonicsGraph
            data={filteredgraphData}
            GatewayID={gateway}
            nodeSelect={node}
            showTime={showTime}
            graphHeight="380px"
          ></CurrentHermonicsGraph>
        );

      case "ShiftwiseEnergy":
        return (
          <ShiftWiseEnergyGraph
            data={shiftsGraphData}
            GatewayID={gateway}
            nodeSelect={node}
            showTime={showTime}
            graphHeight="380px"
          ></ShiftWiseEnergyGraph>
        )
      default:
        return <></>;

    }
  };
  let navigate = useNavigate();
  let maxDate = new Date();

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <PrimeReactProvider>
          <Box>
            <form onSubmit={submitHandler}>
              <div className="text-center w-[80%] mx-auto relative  flex justify-center items-center min-h-[48px] ">
                <div className="absolute left-0">
                  <IconButton onClick={() => navigate(-1)}>
                    <ArrowBack
                      sx={{ fontSize: "40px", color: "#818181" }}
                    ></ArrowBack>
                  </IconButton>
                </div>
                <h1 className=" text-[#545454] w-[60%]">
                  {node !== ""
                    ? `${gateWayIds[gateway].name} | ${nodes[node].name}`
                    : "SELECT GATEWAY AND NODE"}
                </h1>
              </div>
              <Divider></Divider>
              <div className="flex gap-2 flex-col w-[80%] mx-auto mt-3">
                <div>
                  <div className="flex flex-wrap gap-2 items-center ">
                    <Selector
                      values={Object.keys(gateWayIds)}
                      names={gatewayName}
                      select={gateway}
                      name="Gateway"
                      handleChange={handleGateWay}
                      required={true}
                    ></Selector>
                    <Selector
                      values={Object.keys(nodes)}
                      select={node}
                      names={nodeNames}
                      name="Node"
                      handleChange={handleNode}
                      required={true}
                    ></Selector>
                    <Selector
                      values={[
                        "Energy",
                        "ShiftwiseEnergy",
                        "Load",
                        "Current",
                        "CurrentHermonics",
                        "Voltage",
                        "VoltageHermonics",
                        "Power factor",
                        "Carbon emissions",
                      ]}
                      names={[
                        "Energy",
                        "Shiftwise Energy",
                        "Load",
                        "Current",
                        "Current Harmonics",
                        "Voltage",
                        "Voltage Harmonics",
                        "Power factor",
                        "Carbon emissions",
                      ]}
                      select={dataType}
                      name="Data Type"
                      handleChange={handleDataType}
                      required={true}
                    ></Selector>
                  </div>
                </div>
                <div>
                  <div className="flex flex-wrap gap-2 justify-start items-center">
                    <div className="">
                      <Box className="flex items-center bg-white p-1 pl-2 rounded-lg w-fit  ">
                        <p className="whitespace-nowrap mr-2 text-sm text-[#818181]">
                          START DATE
                        </p>
                        <Calendar
                          value={startDat}
                          onChange={(e) => setStartDate(e.value)}
                          className="h-7 w-[110px]"
                          required={true}
                          placeholder="DD/MM/YYYY"
                          inputClassName={"inputClass"}
                          dateFormat="dd/mm/yy"
                          showIcon={false}
                          maxDate={maxDate}
                        />
                        <p className="whitespace-nowrap ml-2 mr-2 text-sm text-[#818181]">
                          :
                        </p>

                        <TimePicker
                          value={fromTime}
                          disabled={dataType === "ShiftwiseEnergy"}

                          viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                          }}
                          onChange={(newValue) => setFromTime(newValue)}
                          slotProps={{
                            textField: {
                              inputProps: {
                                sx: {
                                  height: "26px",
                                  padding: "1px",
                                  width: "110px",
                                  textAlign: "center",
                                  borderRadius: "10px",
                                  color: "#495057",
                                },
                              },
                            },
                          }}
                        />
                      </Box>
                    </div>
                    <div className="">
                      <Box className="flex items-center bg-white p-1 pl-2 rounded-lg w-fit   ">
                        <p className="whitespace-nowrap mr-2 text-sm text-[#818181]">
                          TO DATE
                        </p>
                        <Calendar
                          value={endDat}
                          onChange={(e) => setEndDate(e.value)}
                          placeholder="DD/MM/YYYY"
                          inputClassName={"inputClass"}
                          className="h-7 w-[110px]"
                          dateFormat="dd/mm/yy"
                          minDate={startDat}
                          maxDate={maxDate}
                          required={true}
                        />
                        <p className="whitespace-nowrap ml-2 mr-2 text-sm text-[#818181]">
                          :
                        </p>

                        <TimePicker
                          value={toTime}
                          disabled={dataType === "ShiftwiseEnergy"}

                          viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                          }}
                          onChange={(newValue) => setToTime(newValue)}
                          slotProps={{
                            textField: {
                              inputProps: {
                                sx: {
                                  height: "26px",
                                  padding: "1px",
                                  width: "110px",
                                  textAlign: "center",
                                  borderRadius: "10px",
                                  color: "#495057",
                                },
                              },
                            },
                          }}
                        />
                      </Box>
                    </div>
                  </div>
                </div>
                <div className="flex gap-2 items-center flex-wrap">

                  {dataType === "ShiftwiseEnergy" ?

                    <div className="bg-white rounded-lg flex w-fit p-1 items-center flex-wrap">
                      <span className=" pl-1 text-sm  text-[#818181]">
                        {" "}
                        Presets :
                      </span>
                      <div className="pl-2 flex gap-2 flex-wrap w-fit">
                        {[
                          { id: "1hr", text: "1 HOUR" },
                          { id: "24hr", text: "24 HOURS" },
                          { id: "1week", text: "1 WEEK" },
                          { id: "1month", text: "1 MONTH" },
                        ].map((preset, i) => {
                          return (
                            <div
                              id={preset.id}
                              key={i}
                              className="px-2 py-1 text-sm rounded-lg min-w-[50px] flex justify-center items-center text-[#cac9c9]"
                            >
                              {preset.text}
                            </div>
                          );
                        })}


                      </div>
                    </div>

                    :
                    <div className="bg-white rounded-lg flex w-fit p-1 items-center flex-wrap">
                      <span className=" pl-1 text-sm  text-[#818181]">
                        {" "}
                        Presets :
                      </span>
                      <div className="pl-2 flex gap-2 flex-wrap w-fit">
                        {[
                          { id: "1hr", text: "1 HOUR" },
                          { id: "24hr", text: "24 HOURS" },
                          { id: "1week", text: "1 WEEK" },
                          { id: "1month", text: "1 MONTH" },
                        ].map((preset, i) => {
                          return (
                            <div
                              id={preset.id}
                              key={i}
                              onClick={handlePreset}
                              className={`px-2 py-1 text-sm rounded-lg min-w-[50px] flex justify-center items-center cursor-pointer hover:shadow-md whitespace-nowrap ${activePreset === preset.id ? "bg-[#EF8E3F] text-white" : "bg-[#dfdede] text-[#454545]"}`}
                            >
                              {preset.text}
                            </div>
                          );
                        })}


                      </div>
                    </div>
                  }

                  <PresetsModal
                    open={openPreset}
                    handleOpen={handleOpenPreset}
                    type="changePreset"
                    handleClose={handleClosePreset}
                  />

                  {

                    dataType === "ShiftwiseEnergy" ?
                      !clientData.Presets ? (
                        <Link
                          to="#"
                          onClick={handleOpenPreset}
                          className="text-blue-500 underline cursor-pointer"
                        >
                          Click here to set your preset
                        </Link>
                      ) : (
                        <CustomBtn
                          sx={{
                            fontSize: "10px",
                            align: "right",
                            height: "26px",
                          }}
                          type="submit"
                        >
                          Submit
                        </CustomBtn>
                      )
                      : <CustomBtn
                        sx={{
                          fontSize: "10px",
                          align: "right",
                          height: "26px",
                        }}
                        type="submit"
                      >
                        Submit
                      </CustomBtn>

                  }


                </div>


              </div>

              <Box className="bg-white h-[400px] mx-auto rounded-lg mt-3 relative w-[80%] mb-2">
                {showMessage && (
                  <p className="absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2 p-4 bg-black/50 text-white rounded-md shadow-md font-semibold z-10">
                    No data within given range
                  </p>
                )}
                {loading ? (
                  <div className="h-[400px] flex justify-center items-center">
                    <CircularProgress
                      sx={{ color: "#EF8E3F" }}
                    ></CircularProgress>
                  </div>
                ) : (
                  true && renderGraph(dataType)
                )}
              </Box>
            </form>
          </Box>
        </PrimeReactProvider>
      </LocalizationProvider>
    </>
  );
};

export default Analytics;
